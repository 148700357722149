
import { defineComponent, onMounted, ref } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { io } from "socket.io-client";
import moment from "moment";
import useEmitter from '@/composables/useEmitter'
export default defineComponent({
  name: "notifications-menu",
  components: {},
  setup() {
    const store = useStore();
    const notifications = ref([]);
    const showNotification = ref(false);
    const data1 = [
      {
        title: "Project Alice",
        description: "Phase 1 development",
        time: "1 hr",
        icon: "media/icons/duotune/communication/com002.svg",
        state: "primary",
      },
      {
        title: "HR Confidential",
        description: "Confidential staff documents",
        time: "2 hrs",
        icon: "media/icons/duotune/general/gen044.svg",
        state: "danger",
      },
      {
        title: "Company HR",
        description: "Corporeate staff profiles",
        time: "5 hrs",
        icon: "media/icons/duotune/finance/fin006.svg",
        state: "warning",
      },
      {
        title: "Project Redux",
        description: "New frontend admin theme",
        time: "2 days",
        icon: "media/icons/duotune/files/fil023.svg",
        state: "success",
      },
      {
        title: "Project Breafing",
        description: "Product launch status update",
        time: "21 Jan",
        icon: "media/icons/duotune/maps/map001.svg",
        state: "primary",
      },
      {
        title: "Banner Assets",
        description: "Collection of banner images",
        time: "21 Jan",
        icon: "media/icons/duotune/general/gen006.svg",
        state: "info",
      },
      {
        title: "Icon Assets",
        description: "Collection of SVG icons",
        time: "20 March",
        icon: "media/icons/duotune/art/art002.svg",
        state: "warning",
      },
    ];
    const emitter = useEmitter()
    const data2 = [
      {
        code: "200 OK",
        state: "success",
        message: "New order",
        time: "Just now",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "New customer",
        time: "2 hrs",
      },
      {
        code: "200 OK",
        state: "success",
        message: "Payment process",
        time: "5 hrs",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Search query",
        time: "2 days",
      },
      {
        code: "200 OK",
        state: "success",
        message: "API connection",
        time: "1 week",
      },
      {
        code: "200 OK",
        state: "success",
        message: "Database restore",
        time: "Mar 5",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "System update",
        time: "May 15",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Server OS update",
        time: "Apr 3",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "API rollback",
        time: "Jun 30",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Refund process",
        time: "Jul 10",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Withdrawal process",
        time: "Sep 10",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Mail tasks",
        time: "Dec 10",
      },
    ];

    onMounted(() => {
      // console.log('test ');
      getNotification();
      emitter.on("toggle-sidebar2", isOpen => {
          getNotification();
      });
      const socket = io(`${process.env.VUE_APP_SOKET_URL}`, {
        transports: ["websocket"],
      });
      const userData = localStorage.getItem("userdata");
      socket.on("chat_message", (msg) => {
        //console.log(msg);
        if (userData) {
          var objUser = JSON.parse(userData);
          // console.log(objUser);
          // console.log(msg.user_id);
          if (objUser._id == msg.user_id) {
            getNotification();
          }
        }
      });
    });
    const getNotification = () => {
      var request = {
        url: `notifications`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        console.log('======================================data================================');
        console.log(data);
        const messages = data.data.map(item => { return {...item, _id: item._id.number} });
        notifications.value = messages;
        console.log(notifications.value);
        showNotification.value = true;
      });
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD HH:mm");
    };

    const formatText = (value) => {
      console.log(value.length);
      if (value.length > 15) {
        value = value.substring(0, 15) + "...";
      }
      return value;
    };
    const encodeString = (strData) =>{
      console.log(strData);
      return btoa(strData);
    };
    return {
      data1,
      data2,
      getIllustrationsPath,
      notifications,
      showNotification,
      dateTime,
      formatText,
      encodeString
    };
  },
});
